import React from 'react';
import { variables } from '../../styles/GlobalStyles';
import { Section } from '../../styles/Section';
import styled from 'styled-components';
import Wrapper from '../../styles/Wrapper';
import Draggable from 'react-draggable';

const Header = () => (
  <Section id="header" className="header">
    <Wrapper>
      <Bento>
        <BentoItem className='title'><p>Hi, I'm <span className='small-block'>Jonathan. 👋🏻</span></p></BentoItem>
        <BentoItem className='designer'>
          <span className='desktop'>
            <Draggable bounds={".designer"}>
              <span className='component'><p>I'm a Product Designer.</p></span>
            </Draggable>
          </span>
          <span className='mobile'>
            <span className='component'><p>I'm a <span className='med-block'>Product Designer.</span></p></span>
          </span>
        </BentoItem>
        <BentoItem className='headshot'><img src="/headshot.jpeg" alt="Headshot of Jonathan Hoy" /></BentoItem>
        <BentoItem className='developer'>
          <p className='line line1'><span className='open'>p</span></p>
          <p className='line line2'>I'm also a </p>
          <p className='line line3'>developer.</p>
          <p className='line line4'><span className='close'>p</span></p>
        </BentoItem>
        <BentoItem className='tagline'><p>I design <span>digital experiences</span> that are <span>accessible</span>, <span>intuitive</span>, and a <span>delight to use</span>.</p></BentoItem>
        <BentoItem className='cta'><a href="/#case-study">View featured projects <span className='arrow'>↓</span></a></BentoItem>
      </Bento>
    </Wrapper>
  </Section>
)

const Bento = styled.ul`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 0.75rem;
  @media (max-width: ${variables.sm}) {
    grid-template-rows: auto;
  }
`;

const BentoItem = styled.li`
  background: ${variables.white};
  padding: 1rem;
  border-radius: ${variables.brstandard};
  position: relative;
  /* border: 0.25px solid ${variables.grey200}; */

  /* GRID POSITIONS */
  &.designer {
    grid-row: 2 / 3;
    grid-column: 2 / 10;
  }
  &.title {
    grid-row: 1 / 2;
    grid-column: 2 / 12;
  }
  &.developer {
    grid-row: 3 / 4;
    grid-column: 2 / 4;
  }
  &.headshot {
    grid-row: 2 / 3;
    grid-column: 10 / 12;
  }
  &.tagline {
    grid-row: 3 / 4;
    grid-column: 4 / 10;
  }
  &.cta {
    grid-row: 3 / 4;
    grid-column: 10 / 12;
  }

  @media (max-width: ${variables.md}) {
    &.designer {
      grid-row: 2 / 3;
      grid-column: 1 / 10;
    }
    &.title {
      grid-row: 1 / 2;
      grid-column: 1 / 13;
    }
    &.developer {
      grid-row: 3 / 4;
      grid-column: 1 / 4;
    }
    &.headshot {
      grid-row: 2 / 3;
      grid-column: 10 / 13;
    }
    &.tagline {
      grid-row: 3 / 4;
      grid-column: 4 / 13;
    }
  }

  @media (max-width: ${variables.sm}) {
    &.designer {
      grid-row: 2 / 3;
      grid-column: 1 / 13;
    }
    &.title {
      grid-row: 1 / 2;
      grid-column: 1 / 9;
    }
    &.developer {
      grid-row: 3 / 4;
      grid-column: 1 / 13;
    }
    &.headshot {
      grid-row: 1 / 2;
      grid-column: 9 / 13;
    }
    &.tagline {
      grid-row: 3 / 4;
      grid-column: 1 / 13;
    }
  }

  /* STYLING */
  &.tagline {
    display: flex;
    align-items: center;
    p {
      color: ${variables.grey900};
      font-size: 1.375rem;
      font-weight: 500;
      width: 85%;
      @media (max-width: ${variables.sm}) {
        font-size: 1.25rem;
      }
    }
    span {
      color: ${variables.black};
    }
  }
  &.designer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/grid.png");
    background-position: center;
    background-size: cover;
    position: relative;
    p {
      font-size: 3rem;
      font-weight: 700;
      color: ${variables.black};
      text-align: center;
      @media (max-width: ${variables.md}) {
        font-size: 3rem;
      }
      @media (max-width: ${variables.sm}) {
        font-size: 2rem;
        line-height: 140%;
      }
    }
    @media (max-width: ${variables.md}) {
      .med-block {
        display: block;
      }
    }
    @media (max-width: ${variables.sm}) {
      height: 150px;
    }
  }
  .desktop {
    @media (max-width: ${variables.md}) {
      display: none;
    }
  }
  .mobile {
    @media (min-width: ${variables.md}) {
      display: none;
    }
  }
  .component {
    position: relative;
    display: inline-block;
    &::after {
      content: '';
      position: absolute;
      top: 0rem;
      bottom: 0rem;
      left: -0.5rem;
      right: -0.5rem;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%239747FFFF' stroke-width='2.5' stroke-dasharray='10%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      background-position: center;
      border-radius: 5px;
      z-index: 100;
      @media (max-width: ${variables.sm}) {
        top: -0.125rem;
        bottom: -0.125rem;
        left: -0.5rem;
        right: -0.5rem;
      }
    }
    &::before {
      content: 'What I do';
      font-weight: 400;
      position: absolute;
      font-size: 0.75rem;
      top: -0.875rem;
      left: -0.5rem;
      color: #9747FF;
      background-image: url("/component-icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0 50%;
      padding-left: 0.875rem;
      line-height: 108%;
      @media (max-width: ${variables.sm}) {
        top: -1rem;
        left: -0.5rem;
      }
    }
    @media (max-width: ${variables.sm}) {
      margin-top: 0.75rem;
    }
  }
  &.title {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 4.25rem;
      font-weight: 700;
      color: ${variables.black};
    }
    @media (max-width: ${variables.sm}) {
      p {
        font-size: 2rem;
      }
    }
    @media (max-width: ${variables.sm}) {
      justify-content: flex-start;
      .small-block {
        display: block;
      }
    }
  }
  &.developer {
    padding: 1.5rem 0;
    p {
      font-family: 'SF Mono', monospace;
      font-size: 1.375rem;
      font-weight: 400;
      padding-left: 14.4px;
      display: flex;
      align-items: center;
      &.line2, &.line3 {
        padding-left: 28.8px;
      }
      @media (max-width: ${variables.sm}) {
        font-size: 1.125rem;
      }
    }
    span {
      color: #E53F73;
      &.open {
        &::before {
          content: "<";
        }
      }
      &.close {
        &::before {
          content: "</";
        }
      }
      &::after {
        content: ">";
      }
      &::before, &::after {
        color: ${variables.black};
      }
    }
    @media (max-width: ${variables.sm}) {
      display: none;
    }
  }
  &.headshot {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    img {
      height: 100%;
      object-fit: cover;
      border-radius: ${variables.brstandard};
    }
  }
  &.cta {
    padding: 0;
    a {
      display: block;
      height: 100%;
      padding: 1rem;
      font-size: 1.125rem;
      font-weight: 500;
      border-radius: ${variables.brstandard};
      color: ${variables.white};
      background-image: url("/cta-background.jpg");
      background-size: cover;
      background-position: top;
      transition: background-position 0.3s, text-shadow 0.3s;
      span {
        transition: text-shadow 0.3s;
        font-size: 2.5rem;
        display: block;
      }
      &:hover {
        background-position: bottom;
        text-shadow: 0px 0px 12px rgba(255,79,0,1);
        span {
          text-shadow: 0px 0px 12px rgba(255,79,0,1);
        }
      }
    }
    @media (max-width: ${variables.md}) {
      display: none;
    }
  }


  /* ANIMATIONS */
  &.title {
    animation: enterFromTop 1.25s ease-in-out;
    animation-iteration-count: 1;
  }
  &.headshot {
    animation: enterFromRight 1.25s ease-in-out;
    animation-iteration-count: 1;
  }
  &.designer {
    animation: enterFromLeft 1.25s ease-in-out;
    animation-iteration-count: 1;
  }
  &.developer {
    animation: enterFromBottomLeft 1.25s ease-in-out;
    animation-iteration-count: 1;
  }
  &.tagline {
    animation: enterFromBottom 1.25s ease-in-out;
    animation-iteration-count: 1;
  }
  &.cta {
    animation: enterFromBottomRight 1.25s ease-in-out;
    animation-iteration-count: 1;
  }
  @media (max-width: ${variables.sm}) {
    &.title, &.headshot, &.designer, &.tagline {
      animation: none;
    }
  }
  @keyframes enterFromTop {
    from {
      opacity: 0;
      top: -6rem;
    }
    to {
      opacity: 1;
      top: 0;
    }
  }
  @keyframes enterFromRight {
    from {
      opacity: 0;
      right: -6rem;
    }
    to {
      opacity: 1;
      right: 0;
    }
  }
  @keyframes enterFromBottom {
    from {
      opacity: 0;
      bottom: -6rem;
    }
    to {
      opacity: 1;
      bottom: 0;
    }
  }
  @keyframes enterFromLeft {
    from {
      opacity: 0;
      left: -6rem;
    }
    to {
      opacity: 1;
      left: 0;
    }
  }
  @keyframes enterFromTopLeft {
    from {
      opacity: 0;
      top: -6rem;
      left: -6rem;
    }
    to {
      opacity: 1;
      top: 0;
      left: 0;
    }
  }
  @keyframes enterFromTopRight {
    from {
      opacity: 0;
      top: -6rem;
      right: -6rem;
    }
    to {
      opacity: 1;
      top: 0;
      right: 0;
    }
  }
  @keyframes enterFromBottomRight {
    from {
      opacity: 0;
      bottom: -6rem;
      right: -6rem;
    }
    to {
      opacity: 1;
      bottom: 0;
      right: 0;
    }
  }
  @keyframes enterFromBottomLeft {
    from {
      opacity: 0;
      bottom: -6rem;
      left: -6rem;
    }
    to {
      opacity: 1;
      bottom: 0;
      left: 0;
    }
  }
`;

export default Header;