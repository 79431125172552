// ITEMS SHOULD BE ORDERED NEWEST TO OLDEST

const uiDesignItems = 
[
  {
    name: "Presto Live Activity",
    src: "/thumbnail/presto.png",
    links: [
      {
        href: "https://dribbble.com/shots/24580053-Presto-Live-Activity",
        text: "View shot on Dribbble",
        target: "_blank",
        ariaLabel: "Go to Dribbble, opens in a new window",
      }
    ],
  },
  {
    name: "Stocks",
    src: "/thumbnail/stocks.png",
    links: [
      {
        href: "https://dribbble.com/shots/23182741-Stocks",
        text: "View shot on Dribbble",
        target: "_blank",
        ariaLabel: "Go to Dribbble, opens in a new window",
      },
      {
        href: "https://www.figma.com/proto/iYFJboFG8MoFxLxY91kzcJ/Stocks-App?page-id=4%3A116&type=design&node-id=4-117&viewport=1046%2C509%2C0.35&t=AvsRHhY2htU3a9h1-1&scaling=scale-down&starting-point-node-id=4%3A117&mode=design",
        text: "View Figma prototype",
        target: "_blank",
        ariaLabel: "Go to Figma, opens in a new window",
      }
    ],
  },
  {
    name: "Task Management",
    src: "/thumbnail/task-management.png",
    links: [
      {
        href: "https://dribbble.com/jonathanhoy/collections/5522800-Task-Management-Dashboard",
        text: "View collection on Dribbble",
        target: "_blank",
        ariaLabel: "Go to Dribbble, opens in a new window",
      },
      {
        href: "https://www.figma.com/proto/59JqjdNsnj2HJx0EnMLFWM/Task-Management-Dashboard?page-id=3%3A27&node-id=105%3A3040&viewport=299%2C48%2C0.34&scaling=scale-down&starting-point-node-id=105%3A3040",
        text: "View Figma prototype",
        target: "_blank",
        ariaLabel: "Go to Figma, opens in a new window",
      }
    ],
  },
  // {
  //   name: "Baseball Companion",
  //   src: "/thumbnail/mlb.png",
  //   links: [
  //     {
  //       href: "https://dribbble.com/jonathanhoy/collections/5193478-MLB-Companion-App",
  //       text: "View collection on Dribbble",
  //       target: "_blank",
  //       ariaLabel: "Go to Dribbble, opens in a new window",
  //     },
  //     {
  //       href: "https://www.figma.com/proto/s9DSmnnflh62MS0g0M5vXn/MLB?node-id=183%3A10404&viewport=243%2C48%2C0.12&scaling=scale-down&starting-point-node-id=22%3A58",
  //       text: "View Figma prototype (tablet)",
  //       target: "_blank",
  //       ariaLabel: "Go to Figma, opens in a new window",
  //     },
  //     {
  //       href: "https://www.figma.com/proto/s9DSmnnflh62MS0g0M5vXn/MLB?node-id=336%3A22729&viewport=243%2C48%2C0.6&scaling=scale-down&starting-point-node-id=326%3A16952",
  //       text: "View Figma prototype (mobile)",
  //       target: "_blank",
  //       ariaLabel: "Go to Figma, opens in a new window",
  //     }
  //   ],
  // },
  // {
  //   name: "Food Delivery",
  //   src: "/thumbnail/food-delivery.png",
  //   links: [
  //     {
  //       href: "https://dribbble.com/jonathanhoy/collections/5136028-Food-Delivery-App",
  //       text: "View collection on Dribbble",
  //       target: "_blank",
  //       ariaLabel: "Go to Dribbble, opens in a new window",
  //     }
  //   ],
  // },
  // {
  //   name: "Wallet",
  //   // description: "Concept of an app that aggregates a user's digital cards and passes to conveniently have a user's digital wallet in one place. Users can use the in-app camera to add new cards and passes.",
  //   src: "/thumbnail/wallet.png",
  //   links: [
  //     {
  //       href: "https://dribbble.com/shots/16969923-Wallet-convenient-access-to-digital-passes-tickets-and-cards",
  //       text: "View screens on Dribbble",
  //       target: "_blank",
  //       ariaLabel: "Go to Dribbble, opens in a new window",
  //     },
  //     {
  //       href: "https://www.figma.com/proto/FGqjY1Wz2VGggqxbUGWf1Y/Wallet-App?page-id=9%3A2&node-id=9%3A67&viewport=243%2C48%2C0.54&scaling=scale-down&starting-point-node-id=9%3A67",
  //       text: "View Figma prototype",
  //       target: "_blank",
  //       ariaLabel: "Go to Figma, opens in a new window",
  //     }
  //   ],
  // },
]

export default uiDesignItems;
