/* eslint-disable */
import React, { useEffect } from 'react';

import GlobalStyle from '../styles/GlobalStyles'

import SEO from '../components/seo'
import Nav from '../components/nav'
import Header from '../components/header'
import { CaseStudy } from '../components/design'
import Footer from '../components/footer'

import { Link } from "gatsby";

function IndexPage() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <GlobalStyle />
      <SEO title="Jonathan Hoy - Product Designer" />
      <header>
        <Nav />
        <Header />
      </header>
      <main className='homepage'>
        <CaseStudy showLink={true} />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)