const caseStudyItems = 
[
  {
    name: "Safely",
    description: "Helping travelers with severe food allergies navigate foreign cuisines.",
    src: "/thumbnail/safely.png",
    srclg: "/thumbnail/safely-lg.png",
    href: "/safely",
    class: "safely",
    target: "_self",
    ariaLabel: "Go to Safely case study",
    tags: ["UX Research", "UX Design", "UI Design", "User Testing"],
  },
  {
    name: "Apple Music",
    description: "Designing social features enabling Apple Music users to more easily discover music and connect with friends.",
    src: "/thumbnail/apple-music.png",
    srclg: "/thumbnail/apple-music-lg.png",
    href: "/apple-music",
    class: "apple-music",
    target: "_self",
    ariaLabel: "Go to Apple Music case study",
    tags: ["UX Research", "Heuristic Evaluation", "Competitive Analysis", "UX Design", "UI Design", "User Testing"],
  },
  {
    name: "Design System",
    description: "Documenting and maintaining a design system for my case studies emphasizing the importance of consistency and reusability in design.",
    src: "/thumbnail/design-system.png",
    srclg: "/thumbnail/design-system-lg.png",
    href: "/design-system",
    class: "design-system",
    target: "_self",
    ariaLabel: "Go to Design System case study",
    tags: ["Design System", "Documentation", "UI Design"],
  },
]

export default caseStudyItems;
