// ITEMS SHOULD BE ORDERED NEWEST TO OLDEST

const ppData = 
[
  {
    src: "/promptplayground/pp002.jpg",
    ppNum: "002",
    prompt1: "design",
    prompt2: "an automated tool",
    prompt3: "for",
    prompt4: "a news station in NYC",
    href: "https://dribbble.com/shots/24838753-Prompt-Playground-002",
  },
  {
    src: "/promptplayground/pp001.jpg",
    ppNum: "001",
    prompt1: "design",
    prompt2: "a login dialog",
    prompt3: "for",
    prompt4: "a luxury petfood store",
    href: "https://dribbble.com/shots/24758553-Prompt-Playground-001",
  },
]

export default ppData;
