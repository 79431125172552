import React from 'react';
import caseStudyItems from './data/case-study-items';
import uiDesignItems from './data/ui-design-items';
import ppData from './data/prompt-playground';
import ixdData from './data/ixd';
import { Section, SectionHeading } from '../../styles/Section';
import Wrapper from '../../styles/Wrapper';
import { CaseStudyList, CardList, PromptPlaygroundList, InteractionDesignList } from '../list';
import { Link } from "gatsby";

const CaseStudy = (props) => (
  <Section id="case-study" className="case-study">
    <Wrapper className="heading-wrapper">
      {
        props.showLink === true
        ?
        <div className='header-with-link'>
          <SectionHeading>Featured Projects</SectionHeading>
          <Link to="/design/" aria-label="Navigate to Design page" className='inline'>
            View all design work <span className='arrow'>→</span>
          </Link>
        </div>
        :
        <SectionHeading>Featured Projects</SectionHeading>
      }
      <CaseStudyList data={caseStudyItems} section="design" className="case-study" />
    </Wrapper>
  </Section>
);

const UIDesign = () => (
  <Section id="ui-design" className="ui-design">
    <Wrapper className="heading-wrapper">
      <SectionHeading>Other Works</SectionHeading>
      <CardList data={uiDesignItems} section="design" className="ui-design" columns="2" mdColumns="2" smColumns="1" />
    </Wrapper>
  </Section>
);

const PromptPlayground = (props) => (
  <Section id="prompt-playground" className="prompt-playground">
    <Wrapper className="heading-wrapper">
      {
        props.shortList === true
        ?
        <div className='header-with-link'>
          <SectionHeading>Prompt Playground</SectionHeading>
          {/* <Link to="/design/promptplayground" aria-label="Navigate to Prompt Playground Gallery" className='inline'>
            View the gallery <span className='arrow'>→</span>
          </Link> */}
        </div>
        :
        <SectionHeading>Prompt Playground</SectionHeading>
      }
      <p className='description'>A collection of designs created within a time period with prompts generated from the design tool <a href="https://sharpen.design/" target="_blank" rel="noreferrer" className='inline'>Sharpen <span className='arrow'>→</span></a>.</p>
      <PromptPlaygroundList data={ppData} section="design" className="prompt-playground" columns="2" mdColumns="2" smColumns="1" shortList={props.shortList} />
    </Wrapper>
  </Section>
);

const InteractionDesign = () => (
  <Section id="interaction-design" className="interaction-design">
    <Wrapper className="heading-wrapper">
      <SectionHeading>Interaction Design</SectionHeading>
      <InteractionDesignList data={ixdData} section="design" className="ixd" columns="3" mdColumns="3" smColumns="1" />
    </Wrapper>
  </Section>
)

export { CaseStudy, UIDesign, PromptPlayground, InteractionDesign };