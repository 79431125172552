// ITEMS SHOULD BE ORDERED NEWEST TO OLDEST

const ixdData = 
[
  {
    src: "/ixd/ixd001.mp4",
    number: "001",
    heading: "Toggle",
    color: "white",
  },
]

export default ixdData;
